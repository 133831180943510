<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6" />
    </v-overlay>
    <template v-else>
      <div v-if="!objectHaveLength(conversionDashboard)">
        <v-alert class="tw-mx-16 tw-my-8" type="warning" :icon="icons.mdiAlertOutline">
          {{ $t('forms.conversion.noData') }}
        </v-alert>
      </div>
      <div v-else-if="currentForm" class="forms-conversion">
        <UiBanner>
          <template v-slot:content>
            <LeadsTopBar :formsList="formsList" :currentForm="currentForm" @changeForm="changeForm"
              ><template v-slot:label>
                <div
                  class="tw-flex tw-items-center tw-font-bold tw-text-xl tw-leading-6 tw-flex-grow-1 tw-mr-1"
                  v-dompurify-html="$t('forms.conversion.title.label')"
                ></div>
              </template>
              <template v-slot:actions>
                <div
                  v-if="currentForm.settings.monetized"
                  class="tw-flex tw-items-center tw-flex-row tw-justify-between tw-pt-2 sm:tw-pt-0"
                >
                  <span class="tw-opacity-60 tw-mr-2">{{ $t('forms.conversion.switch.byVolume') }}</span>
                  <v-switch
                    v-model="modelIsDisplayedByValue"
                    :label="$t('forms.conversion.switch.byValue')"
                    hide-details
                  />
                </div>
                <v-badge class="tw-ml-4" overlap color="primary" :content="filterCount" :value="filterCount">
                  <v-btn
                    class="tw-flex tw-ml-2 tw-mt-2 sm:tw-mt-0 tw-leading-2 tw-flex-grow-1"
                    outlined
                    color="primary"
                    rounded
                    :disabled="!hasAvailableFilters"
                    @click="toggleFilters(true)"
                  >
                    <v-icon left>{{ icons.mdiFilterOutline }}</v-icon>
                    <span>{{ $t('button.filter') }}</span>
                  </v-btn>
                </v-badge>
              </template>
            </LeadsTopBar>
          </template>
        </UiBanner>

        <UiContainer class="tw-overflow-y-auto">
          <UiTitle class="tw-pb-8">{{ $t('forms.conversion.charts.title') }}</UiTitle>
          <!-- Status history over time -->
          <LeadsCircleChart
            class="tw-mb-6"
            :circleData="circleData"
            :key="isDisplayedByValue"
            :mode="dashboardDisplayType"
            :currency="currentForm.settings.currency"
          ></LeadsCircleChart>

          <!-- Converted and new leads history by month -->
          <v-card
            class="tw-bg-white forms-conversion__line-charts tw-mb-6"
            :class="{ 'tw-interface-dark-grade-1-color': $vuetify.theme.dark }"
          >
            <div class="tw-flex tw-flex-col sm:tw-flex-row">
              <div class="sm:tw-w-1/2 forms-conversion__line-charts__new tw-p-4">
                <UiTitle>{{ $t('forms.conversion.charts.lineChart.new.title') }}</UiTitle>
                <UiSubtitle>{{ $t('forms.conversion.charts.lineChart.new.subtitle') }}</UiSubtitle>

                <LeadsLineChart
                  :chartOptions="chartNew.options"
                  :chartSeries="isCumulative ? cumulativeSeries(chartNew.series) : chartNew.series"
                  :key="isDisplayedByValue"
                ></LeadsLineChart>
              </div>
              <div class="sm:tw-w-1/2 tw-p-4">
                <div class="tw-flex">
                  <div>
                    <UiTitle>{{ $t('forms.conversion.charts.lineChart.converted.title') }}</UiTitle>
                    <UiSubtitle>{{ $t('forms.conversion.charts.lineChart.converted.subtitle') }}</UiSubtitle>
                  </div>
                  <v-spacer></v-spacer>
                  <v-switch
                    v-model="isCumulative"
                    :label="$t('forms.conversion.charts.lineChart.cumulative')"
                    hide-details
                  />
                </div>
                <LeadsLineChart
                  :chartOptions="chartConverted.options"
                  :chartSeries="isCumulative ? cumulativeSeries(chartConverted.series) : chartConverted.series"
                  :key="isDisplayedByValue"
                ></LeadsLineChart>
              </div>
            </div>
          </v-card>

          <!-- Average time to convert history by month -->
          <v-card
            class="tw-bg-white forms-conversion__line-charts tw-mb-6"
            :class="{ 'tw-interface-dark-grade-1-color': $vuetify.theme.dark }"
          >
            <div class="forms-conversion__line-charts__new tw-p-4">
              <div class="tw-flex tw-pb-2">
                <div>
                  <UiTitle>{{ $t('forms.conversion.charts.lineChart.avgTimeToConvert.title') }}</UiTitle>
                  <UiSubtitle>{{ $t('forms.conversion.charts.lineChart.avgTimeToConvert.subtitle') }}</UiSubtitle>
                </div>
                <v-spacer></v-spacer>
                <div
                  class="tw-font-semibold tw-text-sm tw-flex tw-items-center tw-rounded-md tw-bg-primary tw-bg-opacity-20 tw-px-3"
                >
                  {{ $t('forms.conversion.charts.lineChart.avgTimeToConvert.averageTime') }}
                  {{ millisecondsToDays(averageConvertTime) }}
                </div>
              </div>

              <LeadsLineChart
                :chartOptions="chartAverageTimeToConvert.options"
                :chartSeries="chartAverageTimeToConvert.series"
                :key="isDisplayedByValue"
              ></LeadsLineChart>
            </div>
          </v-card>

          <!-- Average cart history by month -->
          <v-card
            v-if="isDisplayedByValue"
            class="tw-bg-white forms-conversion__bar-charts"
            :class="{ 'tw-interface-dark-grade-1-color': $vuetify.theme.dark }"
          >
            <div class="forms-conversion__bar-charts__new tw-p-4">
              <div class="tw-flex tw-pb-2">
                <div>
                  <UiTitle>{{ $t('forms.conversion.charts.barChart.avgCart.title') }}</UiTitle>
                  <UiSubtitle>{{ $t('forms.conversion.charts.barChart.avgCart.subtitle') }}</UiSubtitle>
                </div>
                <v-spacer></v-spacer>
                <div
                  class="tw-font-semibold tw-text-sm tw-flex tw-items-center tw-rounded-md tw-bg-primary tw-bg-opacity-20 tw-px-3"
                >
                  {{ $t('forms.conversion.charts.barChart.avgCart.global') }}
                  {{ formattedPrice({ number: averageCartValue, currency: currentForm.settings.currency }) }}
                </div>
              </div>

              <LeadsBarChart
                :chartOptions="chartAverageCart.options"
                :chartSeries="chartAverageCart.series"
                :key="isDisplayedByValue"
              ></LeadsBarChart>
            </div>
          </v-card>

          <!-- Table charts -->
          <div v-if="isLocatedForm">
            <UiTitle class="tw-pb-8 tw-pt-16">{{
              $t('forms.conversion.charts.tableChart.title', { ...lexicon })
            }}</UiTitle>
            <div class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
              <div class="sm:tw-w-1/2 tw-w-full">
                <UiTitle class="tw-text-lg tw-pb-6">{{
                  $t('forms.conversion.charts.tableChart.subtitle.new')
                }}</UiTitle>
                <v-card>
                  <LeadsTableChart
                    :type="'new'"
                    :serie="currentDashbordDisplay.overtime.dealers.topGenerators"
                    :mode="dashboardDisplayType"
                    :key="isDisplayedByValue"
                    :currency="currentForm.settings.currency"
                    :lexicon="lexicon"
                  ></LeadsTableChart>
                </v-card>
              </div>
              <div class="sm:tw-w-1/2 tw-w-full">
                <UiTitle class="tw-text-lg tw-pb-6">{{
                  $t('forms.conversion.charts.tableChart.subtitle.converted')
                }}</UiTitle>
                <v-card>
                  <LeadsTableChart
                    :type="'converted'"
                    :serie="currentDashbordDisplay.overtime.dealers.topConverters"
                    :mode="dashboardDisplayType"
                    :key="isDisplayedByValue"
                    :currency="currentForm.settings.currency"
                    :lexicon="lexicon"
                  ></LeadsTableChart>
                </v-card>
              </div>
            </div></div
        ></UiContainer>

        <PlatformLeadsListFilters
          class="forms-conversion__filters-drawer"
          ref="filters"
          :open="filtersOpen"
          :generic-filters="availableFilters"
          :current-user="currentUser"
          :current-filters="currentFilters"
          :lexicon="currentFormLexicon"
          :realtime="false"
          @toggle="toggleFilters"
          @filter="filterView"
        >
          <template v-slot:append> hihi</template>
        </PlatformLeadsListFilters>
      </div>
    </template>
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiAlertOutline, mdiFilterOutline } from '@mdi/js'

import { DashboardMode, LeadStatus } from '@/config/leads.config'
import { formattedPrice } from '@/utils/helper.util'
import { objectHaveLength } from '@/utils/utilities.util'
import {
  formatNewLeadsLineChart,
  formatConvertedLeadsLineChart,
  formatAverageTimeToConvertLeadsLineChart,
  formatAverageCartBarChart,
  cumulativeSeries,
} from '@/utils/chartsFormatter.utils'
import { millisecondsToDays } from '@/utils/date.util'
import { LeadStatusColors } from '@/config/leads.config'

import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBanner from '@/components/UI/Banner.vue'
import LeadsTopBar from '@/components/Leads/LeadsTopBar'
import { isEmpty } from '@/utils/helper.util'
import LeadsLineChart from '@/components/Leads/Dashboards/LineChart'
import LeadsBarChart from '@/components/Leads/Dashboards/BarChart'
import LeadsCircleChart from '@/components/Leads/Dashboards/CircleChart/CircleChart'
import LeadsTableChart from '@/components/Leads/Dashboards/TableChart'
import PlatformLeadsListFilters from '@/components/Leads/List/Filters.vue'

export default {
  name: 'PlatformConversion',
  components: {
    UiBanner,
    UiContainer,
    UiTitle,
    UiSubtitle,
    LeadsTopBar,
    LeadsCircleChart,
    LeadsLineChart,
    LeadsBarChart,
    LeadsTableChart,
    PlatformLeadsListFilters,
  },
  data() {
    return {
      icons: {
        mdiAlertOutline,
        mdiFilterOutline,
      },
      objectHaveLength,
      formattedPrice,
      DashboardMode,
      isDisplayedByValue: false,
      isCumulative: false,
      cumulativeSeries,
      millisecondsToDays,
      LeadStatusColors,
      filtersOpen: false,
    }
  },
  async mounted() {
    this.setUpdating(true)
    const query = this.$route.query
    this.isDisplayedByValue = this.dashboardDisplayType === DashboardMode.VALUE
    this.setCurrentFilters([])
    try {
      await this.getFormsList()
      isEmpty(query.formId)
        ? await this.changeForm(this.formsList.rows[0])
        : await this.changeForm(this.formsList.rows.find(row => row.id === query.formId))
      this.$router.push({ query: { formId: this.currentForm.id } }).catch(err => err)
    } catch {
      this.setAlert({
        color: 'error',
        text: this.$t('leads.notification.error.noForm'),
      })
    }
    this.setUpdating(false)
  },
  watch: {
    currentForm: {
      async handler(newForm, oldForm) {
        if (newForm !== oldForm && this.currentForm) {
          this.$router.push({ query: { ...this.$route.query, formId: this.currentForm.id } }).catch(err => {
            return err
          })
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      conversionDashboard: state => state.leads.conversionDashboard,
      formsList: state => state.leads.formsList,
      currentForm: state => state.leads.currentForm,
      dashboardDisplayType: state => state.leads.dashboardDisplayType,
      lexicon: state => state.leads.currentFormLexicon,
      genericFilters: state => state.leads.genericFilters,
      currentUser: state => state.backoffice.currentUser,
      currentFormLexicon: state => state.leads.currentFormLexicon,
      currentFilters: state => state.leads.currentFilters,
    }),
    modelIsDisplayedByValue: {
      get() {
        return this.isDisplayedByValue
      },
      set(value) {
        this.setDashboardDisplayType(value ? DashboardMode.VALUE : DashboardMode.VOLUME)
        this.isDisplayedByValue = value
      },
    },
    currentDashbordDisplay() {
      return this.conversionDashboard[this.dashboardDisplayType]
    },
    chartAverageCart() {
      return formatAverageCartBarChart(this.conversionDashboard, this.currentForm.settings.currency)
    },
    chartAverageTimeToConvert() {
      return formatAverageTimeToConvertLeadsLineChart(this.conversionDashboard, this.dashboardDisplayType)
    },
    chartNew() {
      return formatNewLeadsLineChart(
        this.conversionDashboard,
        this.dashboardDisplayType,
        LeadStatus.NEW,
        this.currentForm.settings.currency
      )
    },
    chartConverted() {
      return formatConvertedLeadsLineChart(
        this.conversionDashboard,
        this.dashboardDisplayType,
        LeadStatus.CONVERTED,
        this.currentForm.settings.currency
      )
    },
    circleData() {
      return this.currentDashbordDisplay.overtime.status
    },
    isLocatedForm() {
      return this.currentForm.settings.located || false
    },
    averageConvertTime() {
      return this.conversionDashboard[this.dashboardDisplayType].overtime.history.avgTimeToConverted.average
    },
    averageCartValue() {
      return this.conversionDashboard.byValue.overtime.history.avgCart.average
    },
    hasAvailableFilters() {
      return this.genericFilters.find(filter => filter.type === 'tags')?.values.length
    },
    availableFilters() {
      return this.genericFilters.filter(t => t.type === 'tags')
    },
    filterCount() {
      return this.currentFilters.length
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',

      getFormsList: 'leads/getFormsList',
      setCurrentForm: 'leads/setCurrentForm',
      setDashboardDisplayType: 'leads/setDashboardDisplayType',
      getConversionDashboard: 'leads/getConversionDashboard',
      setCurrentFilters: 'leads/setCurrentFilters',
    }),
    async changeForm(item) {
      this.setUpdating(true)
      await this.setCurrentForm(item.id)
      await this.getConversionDashboard()
      this.setUpdating(false)
      this.modelIsDisplayedByValue = this.currentForm.settings.monetized && this.isDisplayedByValue
    },
    toggleFilters(bool) {
      this.filtersOpen = bool
    },
    async filterView(filters) {
      this.setCurrentFilters(filters)
      this.setUpdating(true)
      await this.getConversionDashboard()
      this.setUpdating(false)
      this.toggleFilters(false)
    },
  },
}
</script>

<style lang="scss">
.forms-conversion {
  .v-select__selection--comma {
    overflow: visible;
    font-weight: 600;
  }

  .v-input--switch {
    margin-top: 0;
    padding-top: 0;
  }

  &__select {
    input {
      width: 0 !important;
    }

    fieldset {
      border: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.forms-conversion {
  &__select {
    @apply tw-font-medium tw-text-lg;

    border: 0;
    background-color: #efefef;
    &__line-charts {
      &__new {
        border-right: 1px solid #d2d2d2;
        border-radius: 0 !important;
      }
    }
  }
}
</style>

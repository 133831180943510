<template>
  <div>
    <v-card class="tw-flex tw-justify-center tw-pt-12 tw-pb-36">
      <div class="tw-flex tw-items-center">
        <LeadsCircleItem
          v-for="(circle, index) in formattedCirclesData"
          :key="index"
          :circle="circle"
          :mode="mode"
          :currency="currency"
        >
          <template v-if="index !== formattedCirclesData.length - 1" v-slot:separator>
            <LeadsProgressLine :timeTo="circle.timeTo" />
          </template>
        </LeadsCircleItem>
      </div>
    </v-card>
  </div>
</template>

<script>
import LeadsCircleItem from '@/components/Leads/Dashboards/CircleChart/CircleItem'
import LeadsProgressLine from '@/components/Leads/Dashboards/CircleChart/ProgressLine'
import { formatLeadsCircles } from '@/utils/chartsFormatter.utils'
import { LeadStatus } from '@/config/leads.config'

export default {
  name: 'LeadsCircleChart',
  props: {
    circleData: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  components: {
    LeadsCircleItem,
    LeadsProgressLine,
  },
  data() {
    return {
      formattedCirclesData: null,
      LeadStatus,
    }
  },
  created() {
    this.formattedCirclesData = formatLeadsCircles(this.circleData)
  },
}
</script>

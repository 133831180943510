<template>
  <v-navigation-drawer
    class="drawer"
    absolute
    temporary
    disable-route-watcher
    right
    :value="open"
    :width="$vuetify.breakpoint.xsOnly ? '100%' : 400"
    @input="toggleFilters"
  >
    <template v-slot:prepend>
      <v-toolbar class="primary tw-flex-grow-0" height="56" :elevation="2">
        <v-btn icon dark @click="toggleFilters(false)" v-if="$vuetify.breakpoint.xsOnly">
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
        <v-toolbar-title class="tw-text-base tw-font-medium tw-text-white">
          {{ $t('leads.list.filters.title') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn class="tw-underline tw-underline-offset-4" dark text @click="clearCurrentFilters()">
          {{ $t('leads.list.filters.clearAll') }}
        </v-btn>
      </v-toolbar>
    </template>
    <div class="tw-p-4">
      <div v-for="(availableFilter, idx) in genericFilters" :key="`genericFilter${idx}`">
        <UiSubtitle
          :subtitle="capitalize($t(`leads.list.filters.filter.${availableFilter.type}.label`, { ...lexicon }))"
          large
        />
        <v-select
          v-if="isAGenericFilter(availableFilter)"
          class="tw-my-2 drawer__truncated-filters"
          v-model="selectedGenericFilters[availableFilter.type]"
          solo
          flat
          outlined
          clearable
          return-object
          hide-details
          :id="availableFilter.type"
          :items="getItemsList(availableFilter)"
          :placeholder="$t(`leads.list.filters.filter.${availableFilter.type}.placeholder`, { ...lexicon })"
          @input="emitRealtimeFilter"
        >
          <template v-if="availableFilter.type === 'status'" v-slot:item="{ item }">
            <v-icon :color="getStatusColor(item)" small>{{ icons.mdiCheckboxBlankCircle }}</v-icon>
            <div class="tw-ml-4">{{ $t(`leads.list.filters.filter.status.${item}`) }}</div>
          </template>
          <template v-if="availableFilter.type === 'status'" v-slot:selection="{ item }">
            <v-icon v-if="item" :color="getStatusColor(item)" small>{{ icons.mdiCheckboxBlankCircle }}</v-icon>
            <div v-if="item" class="tw-ml-4">{{ $t(`leads.list.filters.filter.status.${item}`) }}</div>
            <div v-if="!item" class="tw-ml-4">{{ $t(`leads.list.filters.filter.status.placeholder`) }}</div>
          </template>
        </v-select>

        <PlatformLeadsDateFilters
          v-if="availableFilter.type === 'date'"
          :filter="availableFilter"
          :currentFilters="currentFilters"
          @changeDateRange="setDateFilter"
        />

        <MultiItemsFilter
          v-if="availableFilter.type === 'tags'"
          :filter="availableFilter"
          :currentFilters="currentFilters"
          :items="computedTagsFilters"
          :lexicon="lexicon"
          @changeValues="setTagsFilter"
        />

        <MultiItemsFilter
          v-if="availableFilter.type === 'owner'"
          :filter="availableFilter"
          :currentFilters="currentFilters"
          :items="toAvailableOwners(availableFilter.values)"
          @changeValues="setOwnerFilter"
        />

        <MultiItemsFilter
          v-if="availableFilter.type === 'observer'"
          :filter="availableFilter"
          :currentFilters="currentFilters"
          :items="toAvailableObservers(availableFilter.values)"
          @changeValues="setObserverFilter"
        />
      </div>

      <UiSubtitle
        v-if="fieldFilters.length > 0"
        class="tw-mt-8 tw-mb-4 tw-font-bold"
        :subtitle="capitalize(lexicon.fieldValues)"
        large
      />
      <div v-for="(fieldFilter, idx) in fieldFilters" :key="`fieldFilter${idx}`">
        <UiSubtitle class="tw-mb-2" large :subtitle="fieldFilter.name" />

        <PlatformLeadsFieldFilter
          :filter="fieldFilter"
          :currentFilters="currentFilters"
          :isCountryFilter="fieldFilter.type === 'country'"
          @changeValues="setFieldsFilter($event, fieldFilter)"
        ></PlatformLeadsFieldFilter>
      </div>
    </div>
    <template v-slot:append>
      <div class="pa-2">
        <div v-if="!realtime" class="d-flex drawer__buttons">
          <v-btn block color="primary" text rounded @click="toggleFilters(false)">
            {{ $t('button.close') }}
          </v-btn>
          <v-btn block color="primary" rounded @click="selectFiltersAndCloseDrawer">
            {{ $t('button.filter') }}
          </v-btn>
        </div>
        <v-btn v-else block color="primary" text rounded @click="toggleFilters(false)">
          {{ $t('button.close') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mdiCheckboxBlankCircle, mdiPlus, mdiArrowLeft, mdiDeleteOutline, mdiArrowRightBottom } from '@mdi/js'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import PlatformLeadsDateFilters from '@/components/Leads/List/DateFilters.vue'
import MultiItemsFilter from '@/components/Leads/List/MultiItemsFilter.vue'
import { LeadStatus } from '@/config/leads.config'
import PlatformLeadsFieldFilter from '@/components/Leads/List/FieldFilters.vue'
import { capitalize } from '@/utils/formatter.util'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'PlatformLeadsListFilters',
  components: {
    UiSubtitle,
    PlatformLeadsDateFilters,
    MultiItemsFilter,
    PlatformLeadsFieldFilter,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    genericFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    fieldFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentUser: {
      type: Object,
      required: true,
    },
    lexicon: {
      type: Object,
    },
    realtime: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data: () => ({
    icons: { mdiCheckboxBlankCircle, mdiPlus, mdiArrowLeft, mdiDeleteOutline, mdiArrowRightBottom },
    selectedGenericFilters: {},
    selectedFieldFilters: {},
    dateRange: [
      { slug: 'from', value: null },
      { slug: 'to', value: null },
    ],
  }),
  watch: {
    currentFilters: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.initFilters()
        }
      },
      deep: true,
    },
  },
  computed: {
    computedFilters() {
      const filters = [
        { slug: 'status', value: this.selectedGenericFilters.status },
        this.dateRange[0],
        this.dateRange[1],
        { slug: 'tags', value: this.selectedGenericFilters.tags },
        { slug: 'owner', value: this.selectedGenericFilters.owner },
        { slug: 'observer', value: this.selectedGenericFilters.observer },
        { slug: 'locationId', value: this.getSelectedLocationId(this.selectedGenericFilters.location) },
        ...this.computedFieldFilters,
      ]
      return filters.filter(currentFilter => ![null, undefined, ''].includes(currentFilter.value))
    },
    computedFieldFilters() {
      return Object.keys(this.selectedFieldFilters).map(field => ({
        type: 'fieldValue',
        slug: field,
        value: this.selectedFieldFilters[field],
      }))
    },

    computedTagsFilters() {
      const tagsFilters = this.genericFilters.find(currentFilter => currentFilter.type === 'tags')
      return tagsFilters.values
    },
  },
  created() {
    this.initFilters()
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case LeadStatus.NEW:
          return '#a678e4'
        case LeadStatus.ACCEPTED:
          return 'blue'
        case LeadStatus.CONVERTED:
          return 'green'
        case LeadStatus.LOST:
          return 'red'
        default:
          return 'black'
      }
    },
    getItemsList(filters) {
      if (filters.type === 'status') {
        return filters.values
      } else if (filters.type === 'location') {
        return filters.values.map(filter => filter.name)
      }
      return
    },
    selectFilters() {
      this.$emit('filter', this.computedFilters)
    },
    toggleFilters(bool) {
      this.$emit('toggle', bool)
    },
    selectFiltersAndCloseDrawer() {
      this.$emit('filter', this.computedFilters)
      this.toggleFilters(false)
    },
    clearCurrentFilters() {
      this.dateRange = [
        { slug: 'from', value: null },
        { slug: 'to', value: null },
      ]
      this.tags = []
      this.owner = []
      this.observer = []
      this.selectedGenericFilters = {}
      this.selectedFieldFilters = {}
      this.$emit('filter', this.computedFilters)
    },
    getSelectedLocationId(name) {
      const locationFilters = this.genericFilters.find(filter => filter.type === 'location')
      if (this.selectedGenericFilters.location) {
        const location = locationFilters.values.find(filter => filter.name === name)
        return location.id
      }
    },
    getSelectedLocationName(id) {
      const locationFilters = this.genericFilters.find(filter => filter.type === 'location')
      if (this.selectedGenericFilters.locationId) {
        const location = locationFilters.values.find(filter => filter.id === id)
        return location.name
      }
    },
    setDateFilter(dateRange) {
      this.dateRange = [
        { slug: 'from', value: dateRange[0] },
        { slug: 'to', value: dateRange[1] },
      ]
      this.emitRealtimeFilter()
    },
    setTagsFilter(selectedTags) {
      this.selectedGenericFilters = {
        ...this.selectedGenericFilters,
        tags: selectedTags.join(','),
      }
      this.emitRealtimeFilter()
    },
    setOwnerFilter(selectedOwners) {
      this.selectedGenericFilters = {
        ...this.selectedGenericFilters,
        owner: selectedOwners.join(','),
      }
      this.emitRealtimeFilter()
    },
    setObserverFilter(selectedObservers) {
      this.selectedGenericFilters = {
        ...this.selectedGenericFilters,
        observer: selectedObservers.join(','),
      }
      this.emitRealtimeFilter()
    },
    setFieldsFilter(value, field) {
      const valueToFilter = Array.isArray(value) ? value.join(',') : value

      this.selectedFieldFilters = {
        ...this.selectedFieldFilters,
        [field.slug]: valueToFilter,
      }
      this.emitRealtimeFilter()
    },
    resetData() {
      Object.assign(this.$data, {})
    },
    initFilters() {
      this.selectedGenericFilters = this.currentFilters.reduce((filterParams, filter) => {
        filterParams[filter.slug] = filter.value
        return filterParams
      }, {})

      if (this.selectedGenericFilters.locationId) {
        this.selectedGenericFilters = {
          ...this.selectedGenericFilters,
          location: this.getSelectedLocationName(this.selectedGenericFilters.locationId),
        }
      }
    },
    isAGenericFilter(filter) {
      return !['tags', 'owner', 'date', 'observer'].includes(filter.type)
    },
    toAvailableOwners(values) {
      return values.map(value => ({
        text: value === this.currentUser.email ? this.$t('leads.list.filters.filter.owner.me') : value,
        value,
      }))
    },
    toAvailableObservers(values) {
      return values.map(value => ({
        text: value === this.currentUser.email ? this.$t('leads.list.filters.filter.observer.label') : value,
        value,
      }))
    },
    emitRealtimeFilter() {
      this.realtime && this.$emit('filter', this.computedFilters)
    },
    capitalize,
    isEmpty,
  },
}
</script>

<style lang="scss">
.drawer {
  z-index: 10000000; // yep
  &__truncated-filters {
    & .v-select__selection--comma {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }

  &__buttons {
    width: 50%;
  }
}
</style>

<template>
  <div
    class="ui-title"
    :class="{
      'ui-title--large': large,
      'ui-title--x-large': xLarge,
      'ui-title--small': small,
      'ui-title--centered': centered,
    }"
  >
    <slot><span v-dompurify-html="title" /></slot>
  </div>
</template>

<script>
export default {
  name: 'UiTitle',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-title {
  @apply tw-flex tw-items-center tw-font-medium tw-text-xl tw-leading-6;

  &--large {
    @apply tw-font-semibold tw-text-2xl tw-leading-7;
  }

  &--x-large {
    @apply tw-font-bold tw-text-3xl tw-leading-8;
  }

  &--small {
    @apply tw-font-medium tw-text-base tw-leading-5;
  }

  &--centered {
    @apply tw-justify-center;
  }
}
</style>

<template>
  <div class="dashboards-tablechart">
    <v-data-table :headers="headers" :items="formattedSerie" hide-default-footer disable-sort>
      <template v-slot:[`item.position`]="{ item }">
        <div
          class="tw-h-full tw--mx-6 tw-flex tw-justify-center tw-items-center"
          :style="{ 'background-color': hexToRgbA(item.color, item.alpha) }"
        >
          <div>{{ item.position }}</div>
        </div>
      </template>
      <template v-slot:[`item.locationName`]="{ item }">
        <div :style="{ opacity: item.opacity }">{{ item.locationName }}</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatTableChartData } from '@/utils/chartsFormatter.utils'
import { capitalize } from '@/utils/formatter.util'
import { hexToRgbA } from '@/utils/utilities.util'
export default {
  name: 'LeadsTableChart',
  props: {
    type: {
      type: String,
      required: true,
    },
    serie: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    lexicon: {
      type: Object,
    },
  },
  data() {
    return {
      hexToRgbA,
    }
  },
  computed: {
    headers() {
      return [
        { text: '#', value: 'position' },
        {
          text: capitalize(this.lexicon.location),
          value: 'locationName',
        },
        { text: this.$t(`forms.conversion.charts.tableChart.tableHeaders.${this.type}.total`), value: 'total' },
      ]
    },
    formattedSerie() {
      return formatTableChartData(this.serie, this.type, this.mode, this.currency)
    },
  },
}
</script>

<style lang="scss">
.dashboards-tablechart {
  tr {
    &:hover {
      background: none !important;
    }
  }
}
</style>

<template>
  <div class="tw-flex tw-items-center">
    <div class="circle-container">
      <div
        class="circle-container__circle"
        :style="{
          'background-image': circleColor(circle),
          height: circleHeight(circle),
          width: circleHeight(circle),
        }"
      ></div>

      <div class="circle-container__informations">
        <div class="circle-container__informations__percentage">{{ circle.percentage }}</div>
        <div class="circle-container__informations__total">
          {{ displayTotal }}
        </div>
        <div class="circle-container__informations__label">{{ circle.label }}</div>
      </div>
    </div>

    <slot name="separator"></slot>
  </div>
</template>

<script>
import { DashboardMode } from '@/config/leads.config'
import { formattedPrice } from '@/utils/helper.util'
export default {
  name: 'LeadsCircleItem',
  props: {
    circle: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayTotal() {
      return this.mode === DashboardMode.VALUE
        ? formattedPrice({ number: this.circle.total, currency: this.currency })
        : this.circle.total
    },
  },
  methods: {
    circleHeight(circle) {
      return `${circle.size}px`
    },
    circleColor(circle) {
      return `radial-gradient(transparent, ${circle.color})`
    },
  },
}
</script>

<style lang="scss">
.circle-container {
  @apply tw-flex tw-items-center;

  height: 90px;
  position: relative;

  &__circle {
    height: 90px;
    border-radius: 100%;
  }

  &__informations {
    text-align: center;
    position: absolute;
    bottom: -90px;
    left: -50px;
    right: -50px;
    margin: auto;
    &__percentage,
    &__label {
      font-size: 0.8rem;
    }
    &__total {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}
</style>

<template>
  <v-select
    class="tw-mb-2"
    v-model="selectedItems"
    solo
    flat
    outlined
    clearable
    hide-details
    :multiple="!isCountryFilter"
    :items="filter.values"
    :placeholder="$t('leads.list.filters.filter.fields.placeholder')"
    @input="setSelectedFields"
  >
    <template v-slot:item="{ item }">
      <v-list-item-title>
        <span v-if="isCountryFilter">
          <UiCountryLabel :label="item" />
        </span>
        <span v-else>{{ item }}</span>
      </v-list-item-title>
    </template>

    <template v-slot:selection="{ item }">
      <UiCountryLabel v-if="isCountryFilter" :label="item" />
      <span v-else>{{ item }}</span>
    </template>
  </v-select>
</template>

<script>
import UiCountryLabel from '@/components/UI/CountryLabel.vue'

export default {
  name: 'PlatformLeadsFieldFilter',
  components: {
    UiCountryLabel,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    currentFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
    isCountryFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    selectedItems: [],
  }),
  created() {
    const currentlySelectedItems = this.currentFilters.find(filter => filter.slug === this.filter.slug)?.value
    if (currentlySelectedItems) {
      this.selectedItems = currentlySelectedItems.split(',')
    }
  },
  watch: {
    currentFilters: {
      async handler(newFilters) {
        if (newFilters.length === 0) {
          this.clearAll()
        }
      },
      deep: true,
    },
  },
  methods: {
    setSelectedFields() {
      this.$emit('changeValues', this.selectedItems)
    },
    clearAll() {
      this.selectedItems = []
    },
  },
}
</script>

<template>
  <div class="leads-top-bar tw-flex tw-items-center tw-flex-col sm:tw-flex-row">
    <slot name="label"></slot>
    <span class="tw-ml-2 tw-mt-2 sm:tw-mt-0 tw-flex-grow-0 tw-flex">
      <span
        class="tw-flex tw-items-center tw-font-bold tw-font-medium tw-text-xl tw-leading-6 tw-flex-grow-1"
        v-if="formsList.rows.length === 1"
        >{{ formsList.rows[0].name }}</span
      >
      <v-select
        class="leads-top-bar__select"
        :value="currentForm"
        v-model="selectedForm"
        return-object
        dense
        outlined
        hide-details
        light
        item-value="id"
        item-text="name"
        v-else
        :items="formsList.rows"
        @input="changeForm"
      >
      </v-select
    ></span>

    <v-spacer />
    <slot name="actions"> </slot>
  </div>
</template>

<script>
export default {
  name: 'LeadsTopBar',
  props: {
    formsList: {
      type: Object,
      required: true,
    },
    currentForm: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedForm: null,
  }),
  created() {
    this.selectedForm = this.currentForm
  },
  methods: {
    changeForm() {
      this.$emit('changeForm', this.selectedForm)
    },
  },
}
</script>

<style lang="scss">
.leads-top-bar {
  &__select {
    input {
      width: 0 !important;
    }

    fieldset {
      border: none;
    }

    border: 0;
    background-color: #efefef;
  }
  .v-select__selection--comma {
    overflow: visible;
    font-weight: 600;
  }

  .selected {
    background: #eeeeee;
  }

  .truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    display: block;
  }

  .truncated * {
    display: inline;
  }

  .empty-field {
    color: #a5a5a5;
  }
}
</style>

<template>
  <div
    class="ui-container"
    :class="{
      'ui-container--with-actions': withActions,
      'ui-container--no-padding': noPadding,
    }"
  >
    <div
      class="ui-container__container"
      :class="{
        'ui-container__container--large': large,
        'ui-container__container--small': small,
        'ui-container__container--align-center': alignCenter,
        'ui-container__container--justify-center': justifyCenter,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiContainer',
  props: {
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    withActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    justifyCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-container {
  @include screen-container($padding: true, $h-center: false);

  @apply tw-px-4 tw-py-6 sm:tw-p-6;

  &--with-actions {
    padding-bottom: $actions-height;
  }

  &--no-padding {
    @apply tw-p-0;
  }

  &__container {
    @apply tw-flex tw-justify-items-start tw-mx-auto tw-w-full tw-flex-col tw-h-full tw-relative;

    &--large {
      @apply tw-max-w-3xl;
    }

    &--small {
      @apply tw-max-w-xl;
    }

    &--align-center {
      @apply tw-items-center;
    }

    &--justify-center {
      @apply tw-justify-center;
    }
  }
}
</style>

<template>
  <div class="progress-line">
    <div class="progress-line__text">{{ millisecondsToDays(timeTo) }}</div>
  </div>
</template>

<script>
import { millisecondsToDays } from '@/utils/date.util'

export default {
  name: 'LeadsProgressLine',
  props: {
    timeTo: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      millisecondsToDays,
    }
  },
}
</script>

<style lang="scss">
.progress-line {
  position: relative;
  border-bottom: 1px solid #d9d9d9;
  width: 150px;

  &__text {
    text-align: center;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 0.8rem;
  }
}
</style>

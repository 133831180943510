<template>
  <v-autocomplete
    class="tw-my-2"
    v-model="selectedItems"
    chips
    color="blue-grey lighten-2"
    clearable
    outlined
    flat
    solo
    multiple
    hide-details
    :items="items"
    :label="$t(`leads.list.filters.filter.${filter.type}.placeholder`, { ...lexicon })"
    @input="setItems"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="removeItem(data.item)"
      >
        {{ data.item.text || data.item }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-content>{{ data.item.text || data.item }}</v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'PlatformLeadsMultiItemsFilters',

  props: {
    items: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    currentFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
    lexicon: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  watch: {
    currentFilters: {
      async handler(newFilters) {
        if (newFilters.length === 0) {
          this.clearAll()
        }
      },
      deep: true,
    },
  },
  data: () => ({
    selectedItems: [],
  }),

  created() {
    const currentlySelectedItems = this.currentFilters.find(filter => filter.slug === this.filter.type)?.value
    if (currentlySelectedItems) {
      this.selectedItems = currentlySelectedItems.split(',')
    }
  },
  computed: {
    selectedItemsValues() {
      return this.selectedItems.map(item => item.value || item)
    },
  },
  methods: {
    setItems() {
      this.$emit('changeValues', this.selectedItemsValues)
    },
    removeItem(item) {
      const index = this.selectedItems.indexOf(item.value || item)
      if (index >= 0) this.selectedItems.splice(index, 1)
      this.$emit('changeValues', this.selectedItems)
    },
    clearAll() {
      this.selectedItems = []
    },
  },
}
</script>

<style lang="scss">
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
  min-height: auto;
}
</style>

<template>
  <div>
    <v-select
      class="tw-my-2"
      v-model="dateOptionSelected"
      solo
      :menu-props="{ maxHeight: 'auto' }"
      flat
      outlined
      clearable
      return-object
      hide-details
      :items="dateOptions"
      :placeholder="$t(`leads.list.filters.filter.date.options.placeholder`)"
      @input="setDateRange($event)"
    >
      <template v-slot:item="{ item }">
        <span>{{ $t(`leads.list.filters.filter.date.options.${item}`) }}</span>
      </template>
      <template v-slot:selection="{ item }">
        <span>{{ $t(`leads.list.filters.filter.date.options.${item}`) }}</span>
      </template>
    </v-select>

    <div v-if="hasCustomOptionSelected">
      <v-checkbox
        v-model="isUniqueDate"
        :label="$t(`leads.list.filters.filter.date.uniqueDay`)"
        @change="resetDateRange"
      ></v-checkbox>

      <v-card class="tw-mx-auto tw-mt-4 tw-text-center" tile>
        <v-date-picker
          v-model="dateRange"
          :range="!isUniqueDate"
          :min="computedAvailableDateRange.min"
          :max="computedAvailableDateRange.max"
          @change="setCustomDateRange"
          no-title
        ></v-date-picker>
      </v-card>
    </div>
  </div>
</template>

<script>
import { formatedDate, getPreviousDateRange } from '@/utils/date.util'

export default {
  name: 'PlatformLeadsDateFilters',

  props: {
    filter: {
      type: Object,
      required: true,
    },
    currentFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({
    dateRange: [],
    dateOptions: [
      // As long as there is no volume these filters are not useful
      // 'today',
      // 'yesterday',
      'thisWeek',
      'lastWeek',
      'thisMonth',
      'lastMonth',
      'thisYear',
      'lastYear',
      'custom',
    ],
    dateOptionSelected: null,
    formatedDate,
    isUniqueDate: false,
  }),

  computed: {
    computedAvailableDateRange() {
      return {
        min: formatedDate(this.filter.values[0], 'YYYY-MM-DD'),
        max: formatedDate(new Date(), 'YYYY-MM-DD'),
      }
    },
    hasCustomOptionSelected() {
      return this.dateOptionSelected === 'custom'
    },
    startRangeDate() {
      return this.dateRange?.length === 2 ? this.dateRange[0] : null
    },
  },

  created() {
    const fromDate = this.currentFilters.find(filter => filter.slug === 'from')?.value
    const toDate = this.currentFilters.find(filter => filter.slug === 'to')?.value

    this.initCustomDateRange(fromDate, toDate)
  },

  watch: {
    currentFilters: {
      async handler(newFilters) {
        if (newFilters.length === 0) {
          this.clearAll()
        }
      },
      deep: true,
    },
  },

  methods: {
    setDateRange(option) {
      let currentDateRange = [null, null]

      switch (option) {
        case null:
          this.dateRange = [null, null]
          this.dateOptionSelected = null
          break
        case 'today':
          currentDateRange = getPreviousDateRange(0, 'day', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'yesterday':
          currentDateRange = getPreviousDateRange(1, 'day', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'thisWeek':
          currentDateRange = getPreviousDateRange(0, 'week', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'lastWeek':
          currentDateRange = getPreviousDateRange(1, 'week', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'thisMonth':
          currentDateRange = getPreviousDateRange(0, 'month', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'lastMonth':
          currentDateRange = getPreviousDateRange(1, 'month', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'thisYear':
          currentDateRange = getPreviousDateRange(0, 'year', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'lastYear':
          currentDateRange = getPreviousDateRange(1, 'year', this.computedAvailableDateRange)
          this.dateOptionSelected = option
          break
        case 'custom':
          this.initCustomDateRange(this.dateRange[0], this.dateRange[1])
          break
        default:
          this.dateOptionSelected = option
          break
      }
      if (this.dateOptionSelected !== null && this.dateOptionSelected !== 'custom') {
        this.dateRange = currentDateRange.map(date => formatedDate(date, 'YYYY-MM-DD'))
      }

      if (this.dateOptionSelected !== 'custom') {
        this.$emit('changeDateRange', this.dateRange)
      }
    },
    initCustomDateRange(fromDate, toDate) {
      if (fromDate && toDate) {
        this.dateOptionSelected = 'custom'
        this.isUniqueDate = false
        this.dateRange = [formatedDate(fromDate, 'YYYY-MM-DD'), formatedDate(toDate, 'YYYY-MM-DD')].sort()

        if (fromDate === toDate) {
          this.dateRange = formatedDate(fromDate, 'YYYY-MM-DD')
          this.isUniqueDate = true
        }
      }
    },

    setCustomDateRange() {
      let dateRange = ['', '']

      if (this.isUniqueDate) {
        dateRange = [this.dateRange, this.dateRange]
      } else {
        dateRange = [this.dateRange[0], this.dateRange[1]].sort()
      }

      this.$emit('changeDateRange', dateRange)
    },
    clearAll() {
      this.dateOptionSelected = null
      this.isUniqueDate = false
      this.dateRange = []
    },
    resetDateRange() {
      if (this.dateRange !== [] && this.isUniqueDate) {
        this.dateRange = ''
      }
    },
  },
}
</script>

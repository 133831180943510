import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { formattedPrice, toArray } from '@/utils/helper.util'
import { millisecondsToDays } from '@/utils/date.util'
import { DashboardMode, LeadStatusColors, LeadStatus } from '@/config/leads.config'
import i18n from '@/i18n/i18n'

dayjs.extend(localeData)

//line charts
export const formatLeadsLineChart = (chart, mode, type, currency) => {
  return {
    series: [
      {
        name: i18n.t(`forms.conversion.charts.lineChart.tooltip.unit.${mode}`),
        data: chart[mode].overtime.history[type].data.values,
      },
    ],

    options: {
      chart: {
        type: 'area',
        height: '350px',
        sparkline: false,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      xaxis: {
        categories: formatTimeRange(
          chart[mode].overtime.history[type].data.xAxis,
          chart[mode].overtime.history[type].data.xAxisUnit
        ),
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return formatTimeRange(
              chart[mode].overtime.history[type].data.xAxis[value - 1],
              chart[mode].overtime.history[type].data.xAxisUnit,
              'MMMM'
            )[0]
          },
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        labels: {
          formatter: val => {
            return formatYAxis(val, mode, currency)
          },
        },
      },
      grid: {
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
  }
}

export const formatNewLeadsLineChart = (chart, mode, type, currency) => {
  const newChart = formatLeadsLineChart(chart, mode, type, currency)
  return {
    ...newChart,
    options: {
      ...newChart.options,
      colors: [LeadStatusColors.new],
    },
  }
}

export const formatConvertedLeadsLineChart = (chart, mode, type, currency) => {
  const newChart = formatLeadsLineChart(chart, mode, type, currency)
  return {
    ...newChart,
    options: {
      ...newChart.options,
      colors: [LeadStatusColors.converted],
    },
  }
}

export const formatAverageTimeToConvertLeadsLineChart = (chart, mode) => {
  const newChart = formatLeadsLineChart(chart, mode, 'avgTimeToConverted')
  return {
    ...newChart,
    series: [
      {
        name: i18n.t(`forms.conversion.charts.lineChart.avgTimeToConvert.tooltip`),
        data: chart[mode].overtime.history.avgTimeToConverted.data.values,
      },
    ],
    options: {
      ...newChart.options,
      colors: [LeadStatusColors.accepted],
      tooltip: {
        x: {
          formatter: function (value) {
            return formatTimeRange(
              chart[mode].overtime.history.avgTimeToConverted.data.xAxis[value - 1],
              chart[mode].overtime.history.avgTimeToConverted.data.xAxisUnit,
              'MMMM'
            )[0]
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        labels: {
          formatter: val => {
            return millisecondsToDays(val) || 'No data'
          },
        },
      },
    },
  }
}

export const formatTimeRange = (xAxis, mode, format = 'MMM', locale = i18n.locale) => {
  require(`dayjs/locale/${locale}.js`)
  dayjs.locale(locale)
  switch (mode) {
    case 'month':
    default:
      return toArray(xAxis).map(step => dayjs().month(step).format(format))
  }
}

export const formatYAxis = (val, mode, currency) => {
  return mode === DashboardMode.VALUE ? formattedPrice({ number: val, currency: currency }) : val
}

// bar charts
export const formatAverageCartBarChart = (chart, currency) => {
  return {
    series: Object.values(LeadStatus).map(status => ({
      name: i18n.t(`forms.conversion.charts.barChart.avgCart.tooltip.${status}`),
      data: chart.byValue.overtime.history.avgCart.data.values[status],
      color: LeadStatusColors[status],
      hidden: status === LeadStatus.LOST,
    })),

    options: {
      highlightOnHover: false,
      chart: {
        highlightOnHover: false,
        type: 'bar',
        height: '350px',
        sparkline: false,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      xaxis: {
        categories: chart.byValue.overtime.history.avgCart.data.xAxis,
        labels: {
          formatter: function (value) {
            return formatTimeRange(value, chart.byValue.overtime.history.avgCart.data.xAxisUnit, 'MMM')[0]
          },
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          formatter: function (value) {
            return formatTimeRange(value, chart.byValue.overtime.history.avgCart.data.xAxisUnit, 'MMMM')[0]
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        labels: {
          formatter: val => {
            return formatYAxis(val, DashboardMode.VALUE, currency)
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        markers: {
          radius: 12,
        },
      },
    },
  }
}

//circle charts
export const formatLeadsCircles = circlesData => {
  return [
    {
      status: LeadStatus.NEW,
      color: LeadStatusColors.new,
      size: calculateSize(100),
      label: i18n.t('forms.conversion.charts.circles.labels.new'),
      total: circlesData.total,
      timeTo: circlesData.avgTimeToAccepted,
    },
    {
      status: LeadStatus.ACCEPTED,
      color: LeadStatusColors.accepted,
      size: calculateSize(circlesData.accepted.percentage),
      label: i18n.t('forms.conversion.charts.circles.labels.accepted'),
      percentage: `${Math.round(circlesData.accepted.percentage)}%`,
      total: circlesData.accepted.total,
      timeTo: circlesData.avgTimeToConverted,
    },
    {
      status: LeadStatus.CONVERTED,
      color: LeadStatusColors.converted,
      size: calculateSize(circlesData.converted.percentage),
      label: i18n.t('forms.conversion.charts.circles.labels.converted'),
      percentage: `${Math.round(circlesData.converted.percentage)}%`,
      total: circlesData.converted.total,
    },
  ]
}

export const calculateSize = percentage => {
  return (percentage / 100) * (90 - 15) + 15
}

export const cumulativeSeries = series => {
  return series.map(serie => {
    let sum = 0

    return {
      ...serie,
      data: serie.data.map(value => {
        sum += value
        return sum
      }),
    }
  })
}

// Table charts
export const formatTableChartData = (serie, type, mode, currency) => {
  const filledSerie = serie.map((item, index) => {
    return {
      ...item,
      position: index + 1,
      alpha: index < 3 ? 100 / Math.pow(2, index) / 100 : 0,
      color: LeadStatusColors[type],
      total: mode === DashboardMode.VALUE ? formattedPrice({ number: item.total, currency: currency }) : item.total,
      opacity: 1,
    }
  })
  const emptyEntry = {
    position: '',
    alpha: 0,
    locationName: i18n.t('forms.conversion.charts.tableChart.notEnoughData'),
    color: LeadStatusColors[type],
    total: '',
    opacity: 0.6,
  }

  while (filledSerie.length < 5) {
    filledSerie.push(emptyEntry)
  }

  return filledSerie.slice(0, 5)
}

//pie chart
export const formatPieChart = (pieData, currency) => {
  return {
    series: pieData.map(currentData => currentData.value),
    options: {
      labels: pieData.map(currentData => currentData.label),
      chart: {
        type: 'donut',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      legend: {
        show: true,
        markers: {
          radius: 12,
        },
        position: 'bottom',
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formattedPrice({ number: val, currency })
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: {
                show: true,
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
              },
              value: {
                show: true,
                fontWeight: 400,
                formatter: function (val) {
                  return formattedPrice({ number: val, currency })
                },
              },
              total: {
                show: true,
                label: i18n.t('charts.pie.total'),
                fontWeight: 600,
                fontSize: '18px',
                color: '#373d3f',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                  return formattedPrice({ number: total, currency })
                },
              },
            },
          },
        },
      },
    },
  }
}

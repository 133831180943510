<template>
  <apexchart ref="apexChart" height="100%" :options="computedOptions" :series="series" />
</template>

<script>
import Vuetify from '@/plugins/vuetify'
import Apexchart from 'vue-apexcharts'
import { mergeDeep, toArray } from '@/utils/helper.util'

export default {
  name: 'UiApexChart',
  components: {
    Apexchart,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: [Object, Array],
      required: true,
    },
    contentClasses: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    defaultOptions: {
      chart: {
        background: 'transparent',
        fontFamily: 'Poppins, Arial, sans-serif',
        height: '100%',
        animations: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      tooltip: {
        followCursor: true,
        fillSeriesColor: false,
      },
      theme: {
        mode: Vuetify.framework.theme.dark ? 'dark' : 'light',
      },
    },
  }),
  computed: {
    computedOptions() {
      return mergeDeep(this.defaultOptions, this.options)
    },
  },

  async mounted() {
    const seriesToHideByDefault = toArray(this.series).filter(serie => serie.hidden)
    seriesToHideByDefault.forEach(serie => {
      this.$refs.apexChart.toggleSeries(serie.name)
    })
  },
}
</script>

<template>
  <div class="ui-banner" :class="{ 'theme--dark': $vuetify.theme.dark, 'ui-banner--flat': flat }">
    <div
      class="ui-banner__container"
      :class="{ 'ui-banner__container--large': large, 'ui-banner__container--small': small }"
    >
      <div class="ui-banner__container__section ui-banner__container__section--left">
        <slot name="content">
          <UiTitle :title="title" large />
          <v-breadcrumbs
            class="ui-banner__container__section__breadcrumb"
            :items="breadcrumbs"
            v-if="breadcrumbs.length > 1"
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :to="item.to" :exact="true">
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
            <template v-slot:divider>
              <v-icon size="16">{{ icons.mdiChevronRight }}</v-icon>
            </template>
          </v-breadcrumbs>
          <UiSubtitle v-if="subtitle" :subtitle="subtitle" large />
        </slot>
      </div>
      <div class="ui-banner__container__section ui-banner__container__section--right">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'UiBanner',
  components: {
    UiTitle,
    UiSubtitle,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: () => [],
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiChevronRight,
    },
  }),
}
</script>

<style lang="scss">
.ui-banner {
  @apply tw-p-4 sm:tw-px-6;

  border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');
  background: map-get($material-light, 'banner', 'background');

  &.theme--dark {
    border-bottom: 1px solid map-deep-get($material-dark, 'banner', 'border');
    background: map-get($material-dark, 'banner', 'background');
  }

  &--flat {
    border-bottom: 0;
  }

  &__container {
    @apply tw-flex tw-items-center tw-justify-between tw-m-auto tw-w-full;

    &--large {
      @apply tw-max-w-3xl;
    }

    &--small {
      @apply tw-max-w-xl;
    }

    &__section {
      &--left {
        flex: 1;
      }

      &__breadcrumb {
        @apply tw-pl-0;
      }
    }
  }
}
</style>

<template>
  <div
    class="ui-subtitle"
    :class="{
      'ui-subtitle--large': large,
      'ui-subtitle--x-large': xLarge,
      'ui-subtitle--small': small,
      'ui-subtitle--centered': centered,
    }"
  >
    <slot><span v-dompurify-html="subtitle" /></slot>
  </div>
</template>

<script>
export default {
  name: 'UiSubtitle',
  props: {
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-subtitle {
  @apply tw-text-gray-500 dark:tw-text-gray-400 tw-text-sm;

  &--large {
    @apply tw-text-base;
  }

  &--x-large {
    @apply tw-text-lg;
  }

  &--small {
    @apply tw-text-xs;
  }

  &--centered {
    @apply tw-text-center;
  }
}
</style>

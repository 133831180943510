<template>
  <label class="ui-label">
    <img :src="getCountryFlag(label)" :alt="getCountryName(label)" />
    <span v-dompurify-html="highlightText(getCountryName(label), search)" class="tw-text-md" />
  </label>
</template>

<script>
import { countries, flagCountries } from '@/config/countries.config'
import { highlightText } from '@/utils/formatter.util'

export default {
  name: 'UiCountryLabel',
  data: () => ({
    highlightText,
  }),
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    search: {
      type: String,
      required: false,
    },
  },
  methods: {
    getCountryFlag(countryCode) {
      const defaultFlag = flagCountries.find(country => country.code === 'default')
      const country = flagCountries.find(country => country.code === countryCode)
      return country ? country.src : defaultFlag.src
    },

    getCountryName(countryCode) {
      const country = countries[this.$i18n.locale].find(c => c.code === countryCode)
      return country ? country.name : this.$t(`profilehub.dashboard.segments.label.${countryCode || 'other'}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-label {
  @apply tw-inline-flex tw-items-center tw-gap-2;

  margin-bottom: 2px;
}
</style>

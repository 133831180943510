<template>
  <div class="leads-bar-chart">
    <UiApexChart :options="chartOptions" :series="chartSeries" />
  </div>
</template>

<script>
import UiApexChart from '@/components/UI/ApexChart.vue'

export default {
  name: 'LeadsBarChart',
  components: {
    UiApexChart,
  },
  props: {
    chartSeries: {
      type: Array,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.leads-bar-chart {
  @apply tw-flex tw-flex-col;

  height: 350px;
}
</style>
